import { graphql, useStaticQuery } from "gatsby"

interface PageTemplate {
  uuid: string,
  name: string,

  header_items: Array<any>,
  footer_items: Array<any>
}
function usePageTemplates():Array<PageTemplate> {
  const results = useStaticQuery(graphql`
    query GetPageTemplates {
      allStoryblokEntry(
        filter: {field_component: {in: ["standard_page_template", "internal_page_template"]}}
      ) {
        edges {
          node {
            content
            name
            uuid
            slug
          }
        }
      }
    }
  `)

   const templates = results.allStoryblokEntry.edges.map((edge:any)=>{
    const data = JSON.parse(edge.node.content)
    
    return {
      uuid: edge.node.uuid,
      name: edge.node.name,
      header_items: data.header_items || [],
      footer_items: data.footer_items || []
    }
  })

  return templates
}

export { PageTemplate }
export default usePageTemplates