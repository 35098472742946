import * as React from "react"
import { Richtext } from "storyblok-js-client"
import { Helmet } from "react-helmet"

import useStoryblok from "../lib/storyblok"
import GlobalLayout from "../components/layout/GlobalLayout"
import useNotFoundPage, { NotFoundStory } from "../hooks/useNotFoundPage"
import { resolveRichText } from "../siteUtils/richText"
import usePageTemplates, { PageTemplate } from "../hooks/usePageTemplates"
import RenderGlobalComponents from "../globalComponents/GlobalDynamicComponent"

import StandardPageTemplate from "../templates/StandardPage"
import AssetPageTemplate from "../templates/AssetPage"
import TopicPageTemplate from "../templates/TopicPage"


interface ProductionPage {
  content: Richtext,
  page_template: string
}
interface ProductionPageProps {
  story?: SBStory,
  staticStory?: NotFoundStory,
  location: any
}
function ProductionPage({ story, staticStory, location }:ProductionPageProps):JSX.Element {
  const pageTemplates = usePageTemplates()
  let text:Richtext | undefined
  let template:PageTemplate | undefined

  const storyData = story ? story.content as ProductionPage : undefined

  if(storyData && storyData.content) {
    text = storyData.content
    template = pageTemplates.find((t)=>{return t.uuid === storyData.page_template})
  } else if (staticStory) {
    text = staticStory.content
    template = pageTemplates.find((t)=>{return t.uuid === staticStory.page_template})
  }

  let templateHeaderComponents:Array<JSX.Element> = []
  let templateFooterComponents:Array<JSX.Element> = []

  if(template) {
    if(template.header_items) {
      templateHeaderComponents = RenderGlobalComponents(template.header_items, false, location?.pathname)
    }
    if(template.footer_items) {
      templateFooterComponents = RenderGlobalComponents(template.footer_items, false, location?.pathname)
    }
  }

  return (
    <GlobalLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Page Not Found</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      {templateHeaderComponents}
      <section>
        <div className="container flex" style={{paddingTop: 80, paddingBottom: 80}}>
          <div className="rich-text-handler" dangerouslySetInnerHTML={resolveRichText(text)} />
        </div>
      </section>
      {templateFooterComponents}
    </GlobalLayout>
  )
}

interface DevelopmentPageProps {
  story: any,
  location: any
}
function DevelopmentPage({ story, location }:DevelopmentPageProps):JSX.Element {
  const templates = usePageTemplates()
  const storyData = story.story ? story.story : story
  storyData.seo = {}
  const standardTemp = templates.find((t)=>{return t.name === "Standard Page Template"})
  const assetTemp = templates.find((t)=>{return t.name === "Asset Template"})

  switch(storyData.content.component) {
    case "standard_page":
      return (
        <StandardPageTemplate pageContext={{story: story, template: standardTemp}} location={location} />
      )
    case "404_page":
      return (
        <ProductionPage story={story} location={location} />
      )
    case "asset":
      return (
        <AssetPageTemplate pageContext={{story: story, template: assetTemp}} location={location} />
        )
    case "topic":
      return (
        <TopicPageTemplate pageContext={{story: story, template: assetTemp}} location={location} />
      )
    default:
      console.warn("Unknown Page Type: ", storyData.content.component)
      return (<p style={{textAlign: "center", marginTop: "20vh"}}>Storyblok has not been configured to render this type of page yet.</p>)
  }
}

interface NotFoundPageProps {
  location: any
}
const NotFoundPage = ({ location }:NotFoundPageProps) => {
  let story = useStoryblok(null, location)
  let staticStory = useNotFoundPage()

  if(story) {
    return <DevelopmentPage story={story} location={location} />
  } else {
    return <ProductionPage staticStory={staticStory} location={location} />
  }
}

export default NotFoundPage
